import React, { Component, createRef } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import Fuse from "fuse.js"
import { Button, Container, Grid, Input, List, ListItem, Segment } from 'semantic-ui-react'
import { Slider } from "react-semantic-ui-range"
import _ from 'lodash'
import Branding from '../Components/Branding'
import { doingSearch, getWork, setSearchName, setSearchKeyword, setSearchType, setCodes, setSelectedNames } from '../Store/actions'


//TODO change this to import
var rawData = require("../data/austlang.json")

class SearchPage extends Component {
    inputRef = createRef()
    state = {
        activeIndex: null,
        austlangData: rawData,  // The language names
        searchName: '',         // Language name
        searchKeyword: '',      // Keyword
        searchType: '',         // Type of search e.g. language names or keyword
        searchNames: [],        // Language names
        codes: [],              // array of just the matched codes
        sliderMin: '',          // Fuse sensitivity MIN
        sliderValue: '',        // Fuse sensitivity
        maxNameOptions: 200,    // Limit fuse results
        maxTroveParams: 30,     // Trove API limit
        nameCheckStatus: "ready", // Have we checked yet?
        queryUrl: ''            // Trove query
    }

    componentDidMount = () => {
        const { searchName, searchKeyword, codes, searchNames, sliderMin, sliderValue} = this.props
        this.setState({ searchName, searchKeyword, codes, searchNames, sliderMin, sliderValue })

        // Auto press the check names button in testing. Helps to also set a default name in reducer
        if (this.props.searchName) setTimeout(() => this.fuzzySearchNames(), 1000)

        // Save name text in redux state
        this.debounceSavesearchName = _.debounce(e => {
            this.props.setSearchName(e.target.value)
        }, 500);

        // Save keyword text in redux state
        this.debounceSavesearchKeyword = _.debounce(e => {
            this.props.setSearchKeyword(e.target.value)
        }, 500);

        this.inputRef.current.focus()
    }

    

    // Save the name text
    onChangesearchName = (e) => {
        e.persist();
        this.debounceSavesearchName(e);
        this.setState({ searchName: e.target.value })
    }

    // Save the keyword text
    onChangesearchKeyword = (e) => {
        e.persist();
        this.debounceSavesearchKeyword(e);
        this.setState({ searchKeyword: e.target.value })
    }

    // Press Enter in the input field
    handleNameKeyPress = e => {
        if (e.key === 'Enter') {
            this.setState({ searchName: e.target.value })
            this.fuzzySearchNames()
        }
    }

    // Press Enter in the input field
    handleKeywordKeyPress = e => {
        if (e.key === 'Enter') {
            this.setState({ searchKeyword: e.target.value })
            const {
                getWork,
                history,
                searchZone } = this.props
            this.props.setSearchType('keyword')
            getWork(e.target.value, searchZone, '*', 'keyword')
            // Go to results page and wait for results
            history.push('/results')
        }
    }

    // Adjust the specificity slider
    handleSliderChange = value => {
        this.setState({ sliderValue: value })
    }

    // Press the check names button
    handleCheckNamesButton = () => {
        this.fuzzySearchNames()
    }


    // Trigger fuzzy search
    fuzzySearchNames = () => {
        const { austlangData, searchName, sliderValue } = this.state
        const { setSelectedNames } = this.props
        const fuseOptions = {
            keys: ['name'],
            includeScore: true,
            minMatchCharLength: 4,
            threshold: 1 - sliderValue
        }
        const fuse = new Fuse(austlangData, fuseOptions)
        const results = fuse.search(searchName).map(result => result.item)
        const resultNames = results.map(item => item.name)
        const resultCodes = [...new Set(results.map(item => item.code))]

        // Start a timeout for little spinner UI
        setTimeout(() => this.setState({nameCheckStatus: "done"}), 1000)

        // Set the match status of languages in results
        const names = austlangData.map((item, i) => {
            // if name and code match, then set match, selected and show
            // if code matches, then set show
            if (resultNames.includes(item.name) && resultCodes.includes(item.code)) {
                item.match = true
                item.selected = false
                item.show = true
            } else if (resultCodes.includes(item.code)) {
                item.match = false
                item.selected = false
                item.show = true
            } else {
                item.match = false
                item.selected = false
                item.show = false
            }
            return item
        })

        // Update state and redux
        setSelectedNames(names)
        this.setState({ searchNames: names, codes: resultCodes, nameCheckStatus: "checking" })
        this.props.setCodes(resultCodes)
    }

    // Click a name button to toggle its "selected" status
    toggleSelectedName = (name, code, e) => {
        const { searchNames } = this.state
        const { setSelectedNames } = this.props
        const names = searchNames.map((item, i) => {
            if (item.name === name && item.code === code) {
                item.selected = !item.selected
            }
            return item
        })
        this.setState({ searchNames: names })
        setSelectedNames(names)
    }

    toggleAllNamesSelectedStatus = ({code='', match=false, toggle=false}) => {
        // Toggle groups of languages: fuzzy matches, or code-groups
        const { searchNames } = this.state
        const names = searchNames.map((item, i) => {
            // only toggle based on match status, then code if not fuzzy
            if (match) {
                if (item.match === match) {
                    item.selected = toggle
                }
            } else {
                if (item.match === match && item.code === code) item.selected = toggle
            }
            return item
        })
        this.setState({ searchNames: names })
    }

    searchTrove = () => {
        const {
            getWork,
            history,
            searchZone } = this.props
        const { searchNames } = this.state
        // Filter the search Names to only pass names of selected ones
        let names = searchNames.filter(name => name.selected)
        names = names.map(item => item.name)
        this.props.setSearchType('language')
        getWork(names, searchZone, '*', 'language')
        // Go to results page and wait for results
        history.push('/results')
    }


    render() {
        const {
            searchName,
            searchNames,
            searchKeyword,
            codes,
            sliderMin,
            sliderValue,
            nameCheckStatus } = this.state

        const sliderSettings = {
            start: sliderValue,
            min: sliderMin,
            max: 1,
            step: 0.05,
            onChange: value => {
                this.handleSliderChange(value);
            }
        }
        //console.log('searchKeyword',searchKeyword);

        // Make Buttons to toggle all on/off
        const buttonTools = ({ code = false, match = false }) => (
            <div className="tools right">
                <span className="label">Select</span>
                <Button.Group size = 'mini'>
                    <Button
                        icon
                        size = 'mini'
                        onClick={e => this.toggleAllNamesSelectedStatus({ match, code, toggle: true })}>
                        All
                    </Button>
                    <Button
                        icon
                        size = 'mini'
                        onClick={e => this.toggleAllNamesSelectedStatus({ match, code, toggle: false })}>
                        None
                    </Button>
                </Button.Group>
            </div>
        )

        // Make buttons for the fuzzy search match names
        const matchedNameEls = searchNames.filter(item => item.match).map((item, i) => {
            // skip if there's an unwanted word like "family" in the name
            const unwanted_words = ["family", "languages"]
            if (new RegExp(unwanted_words.join("|")).test(item.name)) {
                return null
            } else {
                return (
                    <ListItem key={i} className="button-wrapper">
                        <Button
                            content={item.name}
                            label={item.code}
                            labelPosition="right"
                            positive={item.selected}
                            onClick={e => this.toggleSelectedName(item.name, item.code, e)}/>
                    </ListItem>
                )
            }
        })

        // Make groups of buttons for linked austlang names
        const linkedNameGroupEls = codes.map((code, i) => {
            // prep language items
            const items = (searchNames.filter(item => !item.match)
                .filter(other => other.code === code)
                .map((item, j) => {
                    return (
                        <ListItem key={j} className="button-wrapper">
                            <Button
                                content={item.name}
                                label={item.code}
                                labelPosition="right"
                                positive={item.selected}
                                onClick={e => this.toggleSelectedName(item.name, item.code, e)} />
                        </ListItem>
                    )
                }))
            // build els, return them. return null if there are no items for this code
            return (items.length === 0) ? null : (
                <Segment clearing key={i}>
                    {buttonTools({ code: code })}
                    <List>{items}</List>
                </Segment>
            )
        })

        const disableSearch = (searchNames.filter(item => item.selected).length === 0) ? true : false
        return (
            <div className="search page">
                <Container>

                    <Branding />

                    <Segment className="message">
                        <p>
                            <a href="https://trove.nla.gov.au/" title="Trove">Trove</a> is the national search engine for exploring the collections held by Australian libraries, universities, museums, galleries and archives. It’s free and available online all day, every day.
                        </p>
                        <p>
                            Wandan helps you to search for your language materials in Trove using the different spellings that people have used in the past.
                        </p>
                        <p>
                            Search the materials by either entering a Language name or a keyword below.
                        </p>
                    </Segment>


                    <Grid centered className="section" stackable >
                        <Grid.Row columns={1}>
                            <Grid.Column className="bold">
                                <p>1. Search by Language name</p>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row columns={2}>
                            <Grid.Column className="bold">
                                <p>Which Language you are searching for?</p>
                            </Grid.Column>
                            <Grid.Column className="input-container">
                                <Input size="big" placeholder='Enter Language name' icon='search' onChange={this.onChangesearchName} onKeyPress={this.handleNameKeyPress} value={searchName} ref={this.inputRef} />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row columns={2}>
                            <Grid.Column>
                                <p>Choose how closely to match your spelling.</p>
                            </Grid.Column>
                            <Grid.Column>
                                <div className="slider-container">
                                    <p className="label">Loose</p>
                                    { sliderValue !== '' &&
                                    <Slider className="slider" color="blue" settings={sliderSettings} onChange={e => this.handleSliderChange(e)} />
                                    }
                                    <p className="label">Exact</p>
                                </div>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row columns={2}>
                            <Grid.Column>
                                <p>Check for other spellings listed in <a href="https://collection.aiatsis.gov.au/austlang/search" target="_blank" rel="noopener noreferrer">Austlang</a>.</p>
                            </Grid.Column>
                            <Grid.Column>
                                <Button content='Check Names' onClick={this.handleCheckNamesButton} />
                                {nameCheckStatus === 'checking' &&
                                <div className="spinner small" />
                                }
                            </Grid.Column>
                        </Grid.Row>

                        {matchedNameEls.length === 0 && nameCheckStatus === "done" &&
                        <Grid.Row>
                            <Grid.Column width={9}>
                                <p className="friendly message">No languages found at Austlang for that name. Try making the search less exact and check again.</p>
                            </Grid.Column>
                        </Grid.Row>
                        }

                        {matchedNameEls.length > 0 &&
                        <>

                            <Segment>
                                <p className="segment-title">Names that match the spelling</p>
                                {buttonTools({ match: true }) }
                                <List>
                                    {matchedNameEls}
                                </List>
                            </Segment>

                            <Segment>
                                <Grid centered className="section search-trove" stackable>
                                    <Grid.Row columns={2}>
                                        <Grid.Column className="label">
                                            <p>Choose the names you want to search from the box above, or from the names that are linked to them from the boxes below. Then press &lsquo;Search Trove&rsquo; to look up resources that match those names.</p>
                                        </Grid.Column>
                                        <Grid.Column>
                                            <Button disabled={disableSearch} className="" color="blue" content='Search Trove' onClick={this.searchTrove} />
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Segment>

                            <Segment clearing>
                                <p className="segment-title">Names that are linked in Austlang</p>
                                {linkedNameGroupEls}
                            </Segment>

                            <Container className="section search-button-container">
                                <Button disabled={disableSearch} className="" color="blue" content='Search Trove' onClick={this.searchTrove} />
                            </Container>
                        </>
                        }

                    </Grid>

                    <Grid centered className="section" stackable >
                    <Grid.Row columns={1}>
                            <Grid.Column className="bold">
                                <p>2. Or search by keyword</p>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row columns={2}>
                            <Grid.Column className="bold">
                                <p>Enter a search term</p>
                            </Grid.Column>
                            <Grid.Column className="input-container">
                                <Input size="big" icon='search' placeholder='Enter search term' onChange={this.onChangesearchKeyword} onKeyPress={this.handleKeywordKeyPress} value={searchKeyword} ref={this.inputRef} />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row columns={1}>
                            <Grid.Column>
                                <p>Then press the Enter key to search.</p>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>

                    <Segment className="message footer">
                        <p>
                            'Wandan', which rhymes with 'London', is a search command in Wajarri, a language of the Mid West Region of Western Australia. <em>Provided by the Irra Wangga Language Centre.</em>
                        </p>
                        <p>
                            Artwork: Twice Removed ‘Winnam’ (2014) by Megan Cope. <em>Courtesy of the artist.</em>
                        </p>

                    </Segment>
                </Container>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    codes: state.codes,
    searchName: state.searchName,
    searchKeyword: state.searchKeyword,
    searchNames: state.searchNames,
    searchZone: state.searchZone,
    sliderMin: state.sliderMin,
    sliderValue: state.sliderValue
})

const mapDispatchToProps = dispatch => ({
    setSearchName: name => {
        dispatch(setSearchName(name))
    },
    setSearchKeyword: name => {
        dispatch(setSearchKeyword(name))
    },
    setSearchType: name => {
        dispatch(setSearchType(name))
    },
    setCodes: codes => {
        dispatch(setCodes(codes))
    },
    setSelectedNames: names => {
        dispatch(setSelectedNames(names))
    },
    getWork: (names, zone, s, type) => {
        console.log("A names", names)
        // console.log("search dispatch getWork", names, zone, s)
        dispatch(doingSearch())
        dispatch(getWork(names, zone, s, type))
    }
})

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(SearchPage)
)
