// reducer.js
const initialState = {
    searchName: '',
    searchStatus: 'ready',
    searchNames: [],
    searchZone: 'book',
    searchType: 'language',
    codes: [],
    s: '*',
    nextStart: '',
    total: 0,
    n: 0,
    pages: [],
    currentPageIndex: 0,
    sliderMin: 0.6,
    sliderValue: 0.9
}

const rootReducer = (state = initialState, action) => {
    switch (action.type) {

        case 'DOING_SEARCH':
            return { ...state, searchStatus: 'searching' }

        case 'SET_CURRENT_PAGE_INDEX':
            return { ...state, currentPageIndex: action.index }

        case 'SET_NAME_TEXT':
            return { ...state, searchName: action.name }

        case 'SET_KEYWORD_TEXT':
            return { ...state, searchKeyword: action.name }
        
        case 'SET_SEARCH_TYPE':
            return { ...state, searchType: action.name }

        case 'SET_SEARCH_ZONE':
            // console.log("setting zone to", action.zone)
            return { ...state, searchZone: action.zone }

        case 'SET_CODES':
            return { ...state, codes: action.codes }

        case 'SET_SELECTED_NAMES':
            return { ...state, searchNames: action.names, total: 0, pages: [], currentPageIndex: 0 }

        case 'RECEIVED_API_RESPONSE':
            // console.log("reducer API", action.data.response.zone[0].records);
            // arg - some things are works, some things are articles..
            const work_or_article = {
                'book': 'work',
                'picture': 'work',
                'article': 'work',
                'music': 'work',
                'map': 'work',
                'collection': 'work',
                'newspaper': 'article',
                'gazette': 'article',
                'all': 'work'
            }
            const record_type = work_or_article[action.data.response.zone[0].name]
            console.log("record_type", record_type)
            const work = action.data.response.zone[0].records[record_type]
            const s = action.data.response.zone[0].records.s
            const nextStart = action.data.response.zone[0].records.nextStart
            const n = action.data.response.zone[0].records.n
            const total = action.data.response.zone[0].records.total
            // If s is * then start afresh, else add new pages to what we already have
            const pages = (s === '*') ? [{ id: s, work: work }] : [...state.pages, { id: s, work: work}]
            const currentPageIndex = pages.length - 1
            return {
                ...state, searchStatus: 'done', s, nextStart, n, total, pages, currentPageIndex }
        default:
            return state
    }
}

export default rootReducer
